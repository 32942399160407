
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      notfication_settings: [],
      orders: [],
      users_dateils: [],
      Users: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      UsersSearchMode: false,
      searchModel: "",
      state: state,
      vendors: [],
    };
  },
  methods: {
    openDetails(id) {
      this.$router.push("/vendor-profile/" + id);
    },

    addVendorPages() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addVendorPages",
        inputs: [
          { model: "brand_name", type: "text", label: "name" },
          { model: "brand_name_ar", type: "text", label: "name_ar" },
          { model: "image", type: "file", label: "image" },
          { model: "cover_image", type: "file", label: "cover_image" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.sub_serveice_id = 5;
              this.http.do("vendors", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editVendorPages(app) {
      var data = {
        title: "popups.vendor_pages",
        inputs: [
          {
            model: "vendor_id",
            type: "text",
            label: "vendor_id",
            value: app.vendor_id,
          },
          {
            model: "page_type",
            type: "text",
            label: "page_type",
            value: app.page_type,
          },
          { model: "title", type: "text", label: "title", value: app.title },
          {
            model: "content",
            type: "text",
            label: "content",
            value: app.content,
          },
          { model: "image", type: "text", label: "image", value: app.image },
          { model: "vedio", type: "text", label: "vedio", value: app.vedio },
          { model: "status", type: "text", label: "status", value: app.status },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("vendor-pages", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateVendorPages(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "brand_name", type: "text", label: "name" },
          { model: "brand_name_ar", type: "text", label: "name_ar" },
          { model: "image", type: "file", label: "image" },
          { model: "sub_service_id", type: "text", label: "sub_service_id" },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("vendor-pages", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    addUsers() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addUsers",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name",
          },
          {
            model: "email",
            type: "text",
            label: "email",
          },
          {
            model: "tel",
            type: "text",
            label: "tel",
          },
          {
            model: "pass",
            type: "text",
            label: "pass",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("users", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    changeStates(app) {
      var status = 0;
      if (app.status == 1) {
        status = 0;
      }
      if (app.status == 0) {
        status = 1;
      }
      this.http
        .post("users/update-status", { id: app.id, status: status })
        .then((res) => {
          if (res) {
            this.get(1);
          }
        });
    },

    editUsers(app) {
      var data = {
        title: "popups.users",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name",
            value: app.name,
          },

          {
            model: "email",
            type: "text",
            label: "email",
            value: app.email,
          },
          {
            model: "tel",
            type: "text",
            label: "tel",
            value: app.tel,
          },
          {
            model: "pass",
            type: "text",
            label: "pass",
            value: app.pass,
          },
          {
            model: "user_type_id",
            type: "text",
            label: "user_type_id",
            value: app.user_type_id,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("users", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateUsers(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name",
          },
          {
            model: "email",
            type: "text",
            label: "email",
          },
          {
            model: "tel",
            type: "text",
            label: "tel",
          },
          {
            model: "pass",
            type: "text",
            label: "pass",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("users", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.UsersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("users/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
          user_type: 1,
        })
        .then((res) => {
          this.Users = res.data;
          console.log("######## files", this.Users);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.UsersSearchMode = false;
      this.get(this.page);
      this.getPratner();
    },

    getUsers() {
      this.http.get("users").then((res) => {
        this.Users = res.data;
      });
    },
    deleteUsers(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("users", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("users/vendors/paginate", {
          limit: this.limit,
          page: page,
          status: 1,
          user_type: this.$route.params.id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Users = res.data;
          res.data.forEach((item) => {
            this.notfication_settings.push(item.notfication_settings);
          });

          res.data.forEach((item) => {
            this.notfication_settings.push(item.notfication_settings);
          });

          res.data.forEach((item) => {
            this.orders.push(item.orders);
          });

          res.data.forEach((item) => {
            this.users_dateils.push(item.users_dateils);
          });
        });
    },
    getPratner(page) {
      console.log(page);
      this.http
        .post("vendors/none-partner/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.vendors = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getPratner(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.vendors.text')"
    />

    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("users.id") }}</th>
          <th scope="col">{{ $t("users.name") }}</th>
          <th scope="col">{{ $t("users.email") }}</th>
          <th scope="col">{{ $t("users.tel") }}</th>
          <th scope="col">{{ $t("users.balance") }}</th>
          <th scope="col">{{ $t("users.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in Users" :key="app" class="text-center">
          <td>{{ index + 1 }}</td>
          <td>{{ app.name }}</td>
          <td>{{ app.email }}</td>
          <td>{{ app.tel }}</td>
          <td>{{ app.user_wallet?.balance }}</td>
          <td class="d-flex">
            <router-link
              class="btn btn-success mx-1"
              :to="'/transactions/' + app?.user_wallet?.id"
              >{{ $t("popups.transactions") }}</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  
      
       <a v-if="app.status == 1" class="btn btn-danger mx-1" @click="changeStates(app)" href="javascript: void(0);"
              role="button">{{ $t("popups.disable") }}</a>
            <a v-if="app.status == 0" class="btn btn-primary mx-1" @click="changeStates(app)" href="javascript: void(0);"
              role="button">{{ $t("popups.enable") }}</a>
      
        table-->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">
            {{ p }}
          </a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>

    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




